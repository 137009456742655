<template>
  <div class="d-flex justify-content-between align-items-center flex-wrap" v-if="paginate.data != null && paginate.data.length > 0">
    <div class="d-flex flex-wrap py-2 mr-3">
      <a
          :class="{'btn-light-primary': !(Number(paginate.current_page) === 1), 'cursor-initial': Number(paginate.current_page) === 1}"
          class="btn btn-icon btn-sm  mr-2 my-1" @click="changePageNumberDirect(1)"><i class="ki ki-bold-double-arrow-back icon-xs"></i></a>
      <a
          :class="{'btn-light-primary': !(Number(paginate.current_page) <= 1), 'cursor-initial': Number(paginate.current_page) <= 1}"
          class="btn btn-icon btn-sm mr-2 my-1" @click="changePageNumberDirect(paginate.current_page - 1)"><i class="ki ki-bold-arrow-back icon-xs"></i></a>
      <a v-for="(link, index) in paginate.links" v-if="!((index === 0) || (index === paginate.links.length - 1))" :key="link.label" href="#" @click="changePageNumber(link.url)" class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1" :class="{'active': link.active}">{{ link.label }}</a>
      <a
          :class="{'btn-light-primary': !(Number(paginate.current_page) === Number(paginate.last_page)),'cursor-initial': Number(paginate.current_page) === Number(paginate.last_page)}"
          class="btn btn-icon btn-sm mr-2 my-1" @click="changePageNumberDirect(paginate.current_page + 1)"><i class="ki ki-bold-arrow-next icon-xs"></i></a>
      <a
          :class="{'btn-light-primary': !(Number(paginate.current_page) >= Number(paginate.last_page)), 'cursor-initial': Number(paginate.current_page) >= Number(paginate.last_page)}"
          class="btn btn-icon btn-sm mr-2 my-1" @click="changePageNumberDirect(paginate.last_page)"><i class="ki ki-bold-double-arrow-next icon-xs"></i></a>
    </div>
    <div class="d-flex align-items-center py-3">
      <select v-model="perPageProxy" class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary" style="width: 75px;">
        <option :value="limit" v-for="limit in itemsPerPage" :key="limit">{{ limit }}</option>
      </select>
      <span class="text-muted">{{ $t('pagination.show_result',
          {'start': paginate.from === "null" || paginate.from == null ? 0 : paginate.from,
            'end': paginate.to === "null" || paginate.to == null ? 0 : paginate.to,
            'total': paginate.total === "null" || paginate.total == null ? 0 : paginate.total} ) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    paginate: {
      default: {}
    },
    perPage: {
      default: 10,
    },
    page: {
      default: 1,
    },
    itemsPerPage: {
      default: function () {
        return [10, 20, 30, 40, 50]
      },
    },
  },
  mounted(){
  },
  methods: {
    changePageNumber(url){
      if(url == null || url === "null") return;
      let page_number = url.split("page=").pop();
      this.$emit('update:page', page_number);
    },
    changePageNumberDirect(page){
      let currentPage = null;
      if (page < 1) {
        currentPage = 1;
      } else if (page > this.paginate.last_page) {
        currentPage = this.paginate.last_page;
      } else {
        currentPage = page;
      }
      if(this.paginate.current_page !== currentPage) {
        this.$emit('update:page', currentPage);
      }
    }
  },
  computed: {
    perPageProxy: {
      set(value) {
        this.$emit('update:perPage', value)
      },
      get() {
        return this.perPage;
      },
    }
  },
}
</script>

<style scoped>
  .cursor-initial{
    cursor: initial !important;
  }
</style>
