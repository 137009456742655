<template>
  <div>
    <b-table
        :id="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :striped=striped
        :bordered="bordered"
        :borderless="borderless"
        :outlined="outlined"
        :small="small"
        :hover="hover"
        :dark="dark"
        :fixed="fixed"
        :foot-clone="footClone"
        :no-border-collapse="noBorderCollapse"
        :items="items"
        :fields="fields"
        :head-variant="headVariant"
        :table-variant="tableVariant"
        :per-page="perPageProxy"
        :current-page="paginate.currentPage"
        :responsive="responsive"
        show-empty
    >
      <template #empty="scope">
        <div class="text-center text-dark font-size-base py-4">{{ $t("general.no_records_found") }}</div>
      </template>
      <template #emptyfiltered="scope">
        <div class="text-center text-dark font-size-base py-4" >{{ $t("general.no_records_found_filter") }}</div>
      </template>
      <template #cell(image)="data">
        <img :src="data.item.image" style="width: 50px;" class="center"/>
      </template>
      <template #cell(status)="data">
        <span class="badge" :class="getStatusClass(data.item.status_id)">{{ data.item.status }}</span>
      </template>
    </b-table>
    <Pagination v-if="paginate" :paginate="paginate" :page.sync="pageProxy" :perPage.sync="perPageProxy"></Pagination>
  </div>
</template>

<script>

import Pagination from "@/assets/components/dataTable/Pagination";

export default {
  name: "dataTable",
  props: {
    id: {
      default: "my-table",
      required: false
    },
    fields: {
      default: [],
      type: Array,
      required: true
    },
    items: {
      default: [],
      type: Array,
      required: true
    },
    title: {
      default: null,
      type: String
    },
    paginate: null,
    striped: {
      type: Boolean,
      default: true
    },
    sortBy: {
      type: String
    },
    sortDesc: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    borderless: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    footClone: {
      type: Boolean,
      default: false
    },
    headVariant: {
      type: String,
      default: 'none'
    },
    tableVariant: {
      type: String,
      default: 'light'
    },
    noBorderCollapse: {
      type: Boolean,
      default: false
    },
    page: {
      default: 1,
      required: false
    },
    perPage: {
      type: Number,
      default: 10,
      required: false,
    },
    responsive: {
      required: false,
      default: true,
    }
  },
  components: {Pagination},
  data() {
    return {}
  },

  computed: {
    perPageProxy: {
      set(value) {
        this.$emit('update:perPage', value)
      },
      get() {
        return this.perPage;
      },
    },
    pageProxy: {
      set(value) {
        this.$emit('update:page', value)
      },
      get() {
        return this.page;
      },
    },

  },

  methods: {
    getStatusClass(status) {
      let classes = ['badge-success', 'badge-primary', 'badge-warning', 'badge-info', 'badge-light', 'badge-dark']
      return classes[status]
    }
  }
}
</script>

<style scoped>

</style>
